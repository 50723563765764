























import { Component, Vue } from "vue-property-decorator";
import jigsaw from "@/js/jigsaw.js";
import MyForm from "@/components/MyForm.vue";
import { Toast } from "vant";
import Mixin from "@/js/mixins";

let interval: number;
let time: number = 0;
@Component({ components: { MyForm }, mixins: [Mixin] })
export default class LoginForget extends Vue {
  public show: boolean = false; // 验证码滑块验证是否显示
  public form_list: MyForm.formList[] = [];

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.submitRequest(this.$api.getFormData([this.form_list]));
    }
  }
  submitRequest(data) {
    let token = this.$api.getSession("token");
    let _this = this;
    this.$api.request({
      url: "user/login/register-up-password",
      data,
      success(res) {
        Toast({
          message: "密码修改成功",
          onClose: () => {
            if (token) {
              // 登录后修改密码
              _this.$api.href("/");
            } else {
              // 登录前修改密码
              _this.$api.href("/login");
            }
          }
        });
      }
    });
  }
  // 获取验证码
  getCode() {
    if (time) {
      return;
    }
    let form: any = this.$refs.form;
    form.Validate(0);
    if (!form.error.mobile) {
      this.show = true;
    }
  }
  // 60秒倒计时
  countDown() {
    interval = setInterval(() => {
      if (time === 0) {
        clearInterval(interval);
        return this.$set(this.form_list[0], "right_btn", "获取验证码");
      }
      this.$set(this.form_list[0], "right_btn", String(time));
      time--;
    }, 1000);
  }

  mounted() {
    jigsaw.init({
      el: document.getElementById("captcha"),
      onSuccess: () => {
        this.$api.sendCode(
          this.$api.getFormData([this.form_list]).mobile,
          () => {
            time = 60;
            this.show = false;
            this.countDown();
          }
        );
      },
      onFail: () => {
        console.log("失败");
      }
    });
  }

  init() {
    this.show = false;
    this.form_list = [
      {
        name: "mobile",
        value: "",
        label: "手机号码",
        type: "input",
        input_type: "number",
        placeholder: "请输入您的手机号码",
        required: true,
        right_btn: "获取验证码",
        right_bind: this.getCode,
        validate: "phone"
      },
      {
        name: "code",
        value: "",
        label: "验证码",
        type: "input",
        input_type: "number",
        placeholder: "请输入验证码",
        required: true
      },
      {
        name: "password",
        value: "",
        label: "新 密 码",
        type: "password",
        mask: false,
        placeholder: "请输入推荐人手机号码",
        required: true,
        validate_min: 6,
        validate_max: 6,
        label_center: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);
  }
}
